@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	width: 100%;
	height: 100%;
	scroll-snap-type: y mandatory;
	overflow-y: scroll;
}
.landing, .focus {
	scroll-snap-align: start;
}

body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	font-family: 'Work-Sans', sans-serif;
}

::-webkit-scrollbar {
	display: none;
}

@layer base {
	@font-face {
		font-family: 'Work Sans';
		src: url('./fonts/work-sans/WorkSans-Bold.otf') format('truetype'),
			url('./fonts/work-sans/WorkSans-Bold.woff') format('woff');
		font-weight: 700;
	}

	@font-face {
		font-family: 'Work Sans';
		src: url('./fonts/work-sans/WorkSans-SemiBold.otf') format('truetype'),
			url('./fonts/work-sans/WorkSans-SemiBold.woff') format('woff');
		font-weight: 600;
	}

	@font-face {
		font-family: 'Work Sans';
		src: url('./fonts/work-sans/WorkSans-Medium.otf') format('truetype'),
			url('./fonts/work-sans/WorkSans-Medium.woff') format('woff');
		font-weight: 500;
	}

	@font-face {
		font-family: 'Work Sans';
		src: url('./fonts/work-sans/WorkSans-Regular.otf') format('truetype'),
			url('./fonts/work-sans/WorkSans-Regular.woff') format('woff');
		font-weight: 400;
	}

	.nav-link {
		@apply ml-0 mb-16 lg:ml-16 lg:mb-0 pb-2 border-b border-transparent hover:border-yellow-500 duration-500;
	}

	.nav-link-active {
		@apply pb-2 border-b border-yellow-500
	}
}

.main-button:hover img{
	transform: translateX(15%);
	transition: 0.5s ease;
}

/*Transition Group Styles */
/*Hero styles*/
.hero-enter {
  opacity: 0;
}
.hero-enter-active {
  opacity: 1;
	transition: opacity 1s ease-in-out;
	transition-delay: 0.5s;
}
.hero-exit {
  opacity: 0;
}
.hero-exit-active {
  opacity: 0;
	transition: opacity 1s ease-in-out;
	transition-delay: 0.5s;
}

.contact-box {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.slant-bg {
	height: 50rem;
	width: 100%;
	background: url('./images/utils/slantBottom.svg') no-repeat;
	background-position: center;
	background-size: cover;
}

.slant-img {
	width: 100%;
	max-width: 24rem;
}

.bg-logo {
	background-size: 30%;
}

@media screen and (max-height: 550px) {
	.navbar {
		overflow-y: scroll;
	}
}

@media screen and (max-height: 480px) {
	.landing-bg, .landing-overlay {
		height: 35rem;
	}
}

@media screen and (max-width: 600px) {
	.bg-logo {
		background-size: 70%;
	}
}

@media screen and (min-width: 1024px) {
	.slant-img {
		width: 100%;
		max-width: 29.5rem;
	}
}
